import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      text: { 
        spa: "SpA" ,
        ra: "RA",
        buildYour: "Build Your",
        ownCase: "Own Case"
      },
    }
  },
  fr: {
    translation: {
      text: { 
        spa: "Spondylarthropathie" ,
        ra: "PR",
        buildYour: "Montez Votre",
        ownCase: "Propre Dossier"
      },
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    supportedLngs: ['en', 'fr'],
    order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag'],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    },
    resources
  });

export default i18n;
