import { useTranslation } from "react-i18next";

function LangToggle() {
  const { i18n } = useTranslation();

  return (
    <div className="btn-group p-3 lang-buttons">
      {["en", "fr"].map(x => (
        <button className={`btn btn-sm btn${x === i18n.language ? "" : "-outline"}-light`}
          key={x}
          value={x}
          disabled={x === i18n.language}
          onClick={() => i18n.changeLanguage(x)}
        >
          {x.toUpperCase()}
        </button>
      ))}
    </div>
  );
}

export default LangToggle
