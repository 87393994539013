import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import LangToggle from './LangToggle';
import "./i18n";
import { useTranslation } from "react-i18next";

function App() {
  const { t, i18n } = useTranslation();

  return (
    <div className={`App ${i18n.language}`}>
      <LangToggle></LangToggle>
      <img src="/logo.png" className="logo" alt="logo" />
      <div className="position-center">
        <div className="title">
          <h1 className="h1">{t('text.buildYour')}<br />{t('text.ownCase')}:</h1>
          <div className="h1 title-background">{t('text.buildYour')}<br />{t('text.ownCase')}:</div>
        </div>
        <div className="btns">
          <a
            className="btn-large btn-blue"
            href="https://ra.buildyourowncase.ca"
          >
            <span className="btn-inner">{t("text.ra")}</span>
          </a>

          <a
            className="btn-large btn-orange"
            href={`https://spa.buildyourowncase.ca?lng=${i18n.language}`}
          >
            <span className="btn-inner">{t("text.spa")}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default App;
